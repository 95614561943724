import React from 'react';
import styled from 'styled-components';
import Button from '../Button/Button';
import Logo from '../Logo/Logo';

const HeaderContainer = styled.header`
    display: flex;
    align-items: center;
    padding: 20px 40px;
    background-color: #f7f7f8;
    font-family: 'Satochi', sans-serif;
`;

const Header = () => {
    return (
        <HeaderContainer>
            <Button
                type="primary"
                size="small"
                state="default"
                primaryIcon="menu"
                onClick={() => console.log('Menu clicked')}
            />

            <Button
                type="primary"
                size="small"
                state="default"
                label="Dashboard"
                primaryIcon="home"
                onClick={() => console.log('Dashboard clicked')}
            />

            <Button
                type="primary"
                size="small"
                state="default"
                label="Gérer mes applications"
                primaryIcon="window"
                onClick={() => console.log('app gest clicked')}
            />

            <Logo width="107px" height="31px" />

            <Button
                type="primary"
                size="small"
                state="default"
                primaryIcon="settings"
                onClick={() => console.log('settings clicked')}
            />

        </HeaderContainer>
    );
};

export default Header;
