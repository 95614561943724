import React from 'react';
import Header from '../../components/global/Header/Header';
import styled from 'styled-components';

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f7f7f8;
  height: 100vh;
  overflow: auto;
  font-family: 'Roboto', sans-serif;
`;

const Dashboard: React.FC = () => {
  return (
    <div>
      <Header />
      
      <DashboardContainer>
        <h1>La suite</h1>
      </DashboardContainer>
    </div>
  );
};

export default Dashboard;
