import IconMenu from '../../assets/icons/menu.svg';
import IconHome from '../../assets/icons/home.svg';
import IconWindow from '../../assets/icons/app-window.svg';
import IconSettings from '../../assets/icons/settings.svg';

interface IconProps {
    width: string;
    height: string;
    color: string;
    type: string;
}

const Icon = ({width, height, color, type}: IconProps) => {
    return (
        <>
            {type === "menu" && <img src={IconMenu} alt="menu" width={width} height={height} style={{fill: color}} />}
            {type === "home" && <img src={IconHome} alt="home" width={width} height={height} style={{fill: color}} />}
            {type === "window" && <img src={IconWindow} alt="window" width={width} height={height} style={{fill: color}} />}
            {type === "settings" && <img src={IconSettings} alt="settings" width={width} height={height} style={{fill: color}} />}
        </>
    );
}

export default Icon;