import styled, { CSSProperties } from "styled-components";
import { ReactNode } from "react";
import Icon from "../Icon";
import Typography from "../Typo/Typography";

const LabelButton = styled.button<{
    type: "primary" | "secondary" | "terciary";
    size: "smaller" | "small";
    state: "default" | "hover" | "disable";
    hasLabel: boolean;
}>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    border: 1px solid #CBD5E1;
    cursor: pointer;
    background: ${(props) =>
            props.state === "default" ? "#FFF" : props.state === "hover" ? "#F7FAFC" : "#CBD5E1"};
    width: ${(props) =>
            props.hasLabel
                    ? "auto"
                    : props.size === "smaller"
                            ? "32px"
                            : "40px"};

    height: ${(props) => (props.size === "smaller" ? "32px" : "40px")};
    padding: ${(props) =>
            props.hasLabel ? (props.size === "smaller" ? "4px 8px" : "6px 12px") : "0"};
`;

interface ButtonProps {
    type: "primary" | "secondary" | "terciary";
    size: "smaller" | "small";
    state: "default" | "hover" | "disable";
    label?: string;
    primaryIcon?: ReactNode | string;
    secondaryIcon?: ReactNode | string;
    onClick: () => void;
    style?: CSSProperties;
}

const Button = ({ type, size, state, label, primaryIcon, secondaryIcon, onClick, style }: ButtonProps) => {
    return (
        <LabelButton
            type={type}
            size={size}
            state={state}
            hasLabel={!!label}
            onClick={onClick}
            style={style}
        >
            {typeof primaryIcon === "string" ? (
                <Icon type={primaryIcon} width="16px" height="16px" color="#475569" />
            ) : (
                primaryIcon
            )}
            {label && <Typography type={size === "smaller" ? "label-smaller" : "label-small"} text={label} />}
            {typeof secondaryIcon === "string" ? (
                <Icon type={secondaryIcon} width="16px" height="16px" color="#475569" />
            ) : (
                secondaryIcon
            )}
        </LabelButton>
    );
};

export default Button;
