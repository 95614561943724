import styled, {CSSProperties} from "styled-components";

const LabelSmaller = styled.div`
  font-family: "Satoshi";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #475569;
`;

const LabelSmall = styled.div`
  font-family: "Satoshi";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #475569;
`;

interface TypographyProps {
  type: "label-smaller" | "label-small"
  text: string
  style?: CSSProperties
}

const Typography = ({type, text, style}: TypographyProps) => {

  return (
    <div>
      {type === "label-smaller" && <LabelSmaller style={style}>{text}</LabelSmaller>}
      {type === "label-small" && <LabelSmall style={style}>{text}</LabelSmall>}
    </div>
  )
}

export default Typography;